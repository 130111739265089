import React, { useEffect, useState } from 'react';
import firebase from '../../config/Firebase';
import Loading from '../Loading';
import VerifyPopup from '../VerifyPopup';

// Css import
import '../../assets/css/EditComponents/EditInventory.css';

// Components Imports
import TitleEditor from '../FormComponents/TitleEditor';
import HeaderForm from '../FormComponents/HeaderForm';
import ButtonForm from '../FormComponents/ButtonForm';
import { fieldsEditEvents } from '../FieldsForm/events.fields';
import { inputsComponents } from '../../utils/formManager';

export default function EditEvents({ setEditPopup, doc, action, userCity, pendency, objectPend, setObjectPend, setNumPendency, role, tradeAdmin }) {
  const [popup, setPopup] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const [item, setItem] = useState(handleItem());

  function handleItem() {
    if (objectPend)
      return objectPend.currDoc;
  
    return doc ? doc.data() : {
      Title: '',
      Description: '',
      'Long Description': '',
      City: userCity,
      AttractionId: '',
      StartDate: null,
      EndDate: null,
      Visible: true,
      Active: true,
      original: {
        Description: '',
        'Long Description': '',
      },
    };
  }

  const handleEditEvent = async () => {
    try {
      await handleSaveImages('edit');

      const pendency = {
        action: 'Editar',
        city: item.City,
        createDate: new Date(),
        currDoc: item,
        lastDoc: doc.data(),
        requestor: (await getUserInfo()).name,
        type: 'Events',
        emailRequester: (await getUserInfo()).email,
        docId: doc.id,
      };

      await firebase.firestore().collection('Pendencies').add(pendency);
      setNumPendency((num) => num + 1);
      setEditPopup(false);
    } catch (error) {
      setEditPopup(false);
    }
  };

  const handleAddEvent = async () => {
    try {
      await handleSaveImages('create');

      const pendency = {
        action: 'Criar',
        city: item.City,
        createDate: new Date(),
        currDoc: item,
        lastDoc: {},
        requestor: (await getUserInfo()).name,
        type: 'Events',
        emailRequester: (await getUserInfo()).email,
        docId: '',
      };

      await firebase.firestore().collection('Pendencies').add(pendency);
      setNumPendency((num) => num + 1);
      setEditPopup(false);
    } catch (error) {
      setEditPopup(false);
    }
  };

  async function handleSaveImages(type) {
    allImages.map(async (img) => {
      if (type !== 'edit' || img.image !== '') {
        const ref = firebase.storage().ref(`cities/${item.City}/events/${img.name}`);
        await ref.put(img.image);
      }
    });
  }

  async function getUserInfo() {
    const userUid = firebase.auth().currentUser.uid;
    const userData = await firebase.firestore().collection('users').doc(userUid).get();

    return userData.data();
  }

  async function handleSetRegionName(newItem) {
    const docCity = await firebase.firestore().collection('Cities').where('name', '==', newItem.City).get();
    const cityData = docCity.docs[0].data();

    setItem({...newItem, nomeRegiao: cityData.nomeRegiao || '', Estado: cityData.Estado || '' });
  }

  useEffect(() => {
    let newItem = item;
    if (action === 'Criar' && !objectPend)
      newItem = { ...newItem, City: userCity };

    handleSetRegionName(newItem);
  }, [userCity]);

  function handleCheckFields() {
    const keys = ['Title', 'Description', 'Long Description', 'City', 'StartDate', 'EndDate', 'AttractionId'];

    if (item.location) {
      keys.pop();
    }

    for (let i = 0; i < keys.length; i++) {
      if ((item[keys[i]] === '' || item[keys[i]] === 0) || item[keys[i]] === null) {
        document.getElementById(keys[i] + 'Form').focus();
        return;
      }
    }

    if (objectPend)
      handleEditPendency();
    else
      setPopup(true);
  }

  async function handleEditPendency() {
    const newPendency = { ...objectPend, currDoc: item };
    await deleteImagesPendency(objectPend.deleteFiles, objectPend.city);
    await handleSaveImages('edit');
    setObjectPend(newPendency);

    await firebase.firestore().collection('Pendencies').doc(pendency.id).set(newPendency)
      .then(() => setEditPopup(false));
  }

  async function deleteImagesPendency(fileNames, city) {
    if (!fileNames) return;

    fileNames.map(async (nameFile) => {
      const ref = firebase.storage().ref(`cities/${city}/events/${item.Title + '_' + nameFile}`);
      await ref.delete();
    });
  }

  if (!userCity) {
    return <Loading />;
  }

  return (
    <div className='EditInventory'>
      <div className='bodyEditInventory'>
        <TitleEditor action={action} type={'Evento'} />
        <div>
          <form className='form_edit_admin'>
            <HeaderForm />
            {
              fieldsEditEvents.map((field) => {
                return inputsComponents[field.component]({ term: field, item: item, setItem: setItem, doc: doc, userCity: action === 'Criar' && !objectPend ? userCity : item.City, allImages: allImages, setAllImages: setAllImages, objectPend: objectPend, typeDocument: 'events', pathStorage: `cities/${objectPend ? objectPend.currDoc.City : userCity}/events`, action: action === 'Criar', role: role, actionType: action, userRole: role, startDate: 'StartDate', endDate: 'EndDate', setObjectPend: setObjectPend, tradeAdmin: tradeAdmin, rankingPoints: !!field.rankingPoints });
              })
            }
            { popup ? <VerifyPopup setVerifyPopup={setPopup} handleAction={doc ? handleEditEvent : handleAddEvent} title={`Deseja ${action} este Evento?`} message={'Ao realizar essas ações, elas serão gravadas na base de dados, e as novas informações poderão ser vistas posteriormente. Deseja continuar?'} /> : null }
            <ButtonForm setEditPopup={setEditPopup} handleCheckFields={handleCheckFields} />
          </form>
        </div>
      </div>
    </div>
  );
}